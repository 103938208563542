import { configureStore } from "@reduxjs/toolkit";
import loadingReducer from "./states/loading";
import walletReducer from "./states/wallet";
import configReducer from "./states/config";

export default configureStore({
  reducer: {
    wallet: walletReducer,
    loading: loadingReducer,
    config: configReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
