import { useDispatch, useSelector } from "react-redux";
import { useAccount, useConnect } from "wagmi";
import { setShowLogin, setShowProfile, setSignature } from "../../states/wallet";
import "./perfil-login.scss";
import { disconnect, signMessage } from "@wagmi/core";
import { useState } from "react";

const PerfilLogin = () => {
  const dispatch = useDispatch();

  const logo = useSelector((state) => state.config.logo);
  const logoWidth = useSelector((state) => state.config.logoWidth);

  const [showSignButton, setShowSignButton] = useState(false);

  const { address } = useAccount({
    async onConnect({ address, connector }) {
      if (connector.id === "sequence") {
        setShowSignButton(true);
        return;
      }
      login(address);
    },
  });

  const login = (address) => {
    const timestampMs = Date.now();
    const message = "Login with Atyum - " + timestampMs;
    signMessage({ message })
      .then((data) => {
        dispatch(setShowLogin(false));
        dispatch(
          setSignature({ signature: data, timestampMs, signer: address })
        );

        dispatch(setShowProfile(true));
      })
      .catch((error) => {
        disconnect();
        setShowSignButton(false);
        alert(error);
      });
  };

  const { connectors, connect } = useConnect();

  return (
    <div className="row mt-4 justify-center">
      <div className="col-lg-12 justify-center mb-5">
        <img src={logo} alt="" width={logoWidth} />
      </div>
      <div className="col-lg-10 mb-5">
        <div className="row">
          <div className="col justify-center">
            {!showSignButton && (
              <h4 className="wallet_info_txt text-center bold">
                Inicia Sesión
                {showSignButton &&
                  'Sesión iniciada correctamente.<br/> Pulsa "Continuar" para acceder a tu perfil.'}
              </h4>
            )}
            {showSignButton && (
              <h4 className="wallet_info_txt text-center bold">
                Sesión iniciada correctamente.
                <br /> Pulsa "Continuar" para acceder a tu perfil.
              </h4>
            )}
          </div>
        </div>

        <hr className="separator" />
        {showSignButton && (
          <div className="row mt-3">
            <div className="col">
              <button className="connect_btn" onClick={() => login(address)}>
                Continuar
              </button>
            </div>
          </div>
        )}

        {!showSignButton &&
          connectors.map((connector) => {
            return (
              <div key={connector.id} className="row mt-3">
                <div className="col">
                  <button
                    className="connect_btn"
                    onClick={() => connect({ connector })}
                  >
                    {connector.id === "sequence" ? "Email" : connector.name}
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PerfilLogin;
