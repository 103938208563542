import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { refreshKycStatus } from "../../states/wallet";
import "./kyc.scss";

const Kyc = () => {
  const dispatch = useDispatch();
  const kycStatus = useSelector((state) => state.wallet.kycStatus);
  const signature = useSelector((state) => state.wallet.signature);
  const [showEmail, setShowEmail] = useState(false);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newsAccepted, setNewsAccepted] = useState(true);

  const logoWidth = useSelector((state) => state.config.logoWidth);
  const logo = useSelector((state) => state.config.logo);

  const { address } = useAccount();

  const handleChange = () => {
    setNewsAccepted(!newsAccepted);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "var(--bg_color)",
    },
  };

  useEffect(() => {
    dispatch(refreshKycStatus(address));
  }, [signature, address]);

  const openModal = () => {
    setShowEmail(true);
  };

  const closeModal = () => {
    setShowEmail(false);
  };

  const goToKyc = () => {
    let redirect = window.location.href;
    if (window.location.hostname === "localhost") {
      redirect = "https://atyum.com";
    }
    if (kycStatus === "NOTHING" || kycStatus === "PENDING") {
      setLoading(true);
      fetch(
        process.env.REACT_APP_KYC_URL +
          "/kyc/start/" +
          email +
          "/" +
          newsAccepted +
          "/?redirect=" +
          encodeURIComponent(redirect),
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "POST",
          body: JSON.stringify(signature),
        }
      ).then(async (urlData) => {
        if (urlData.status !== 201) {
          alert("Error. Try again!");
          setLoading(false);
          return;
        }
        window.location.href = (await urlData.json()).url;
      });
    }
  };

  if (!kycStatus) {
    return <></>;
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <span
            className="kyc_text"
            onClick={() =>
              kycStatus !== "COMPLETED" && kycStatus !== "COMPLETED_AND_SYNCED"
                ? openModal()
                : () => {}
            }
          >
            {kycStatus === "PENDING" || kycStatus === "NOTHING"
              ? "Realizar KYC"
              : kycStatus === "FAILED"
              ? "Reintentar KYC"
              : kycStatus === "COMPLETED"
              ? "Validando KYC"
              : "KYC Completado"}
          </span>
        </div>
      </div>

      <Modal
        isOpen={showEmail}
        ariaHideApp={false}
        style={customStyles}
        onRequestClose={() => closeModal()}
        contentLabel="KYC"
      >
        <div className="row mb-2 mt-4">
          <div className="col-lg-12 justify-center">
            <img src={logo} alt="" width={logoWidth} />
          </div>
        </div>
        <div className="row text-center justify-center">
          <div className="col-lg-12 mt-4 mb-3">
            <div className="kyc-txt">
              <span>
                KYC es el proceso por el cual identificamos la identidad de
                nuestros clientes. <br /> Esto solo te llevará un par de
                minutos, y ya podrás empezar a invertir!
              </span>
            </div>
          </div>
        </div>
        <div className="row justify-center">
          <div className="col-lg-8 mt-4 mb-3">
            <div className="input_container">
              {/* ToDo: Mover las clases al css generico */}
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="see_sale_detail_card_amount"
                type="text"
                placeholder="Introduce tu email..."
              />
            </div>
          </div>
        </div>
        <div className="row justify-center mt-3 mb-3">
          <div className="col-12 justify-center">
            <input
              type="checkbox"
              checked={newsAccepted}
              onChange={handleChange}
              className="checkbox"
            />
            <span>Acepto recibir notificaciones de la plataforma</span>
          </div>
        </div>
        <div className="row justify-center">
          <div className="col-lg-6 mt-3 mb-4 justify-center">
            {/* ToDo: Mover las clases al css generico */}
            <button
              className={
                email
                  ? "see_sale_detail_card_buy"
                  : "see_sale_detail_card_buy_disabled"
              }
              disabled={!email || loading ? "disabled" : undefined}
              onClick={() => goToKyc()}
            >
              Empezar KYC
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Kyc;
