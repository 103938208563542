import { createSlice } from "@reduxjs/toolkit";
import config from "../config.json";

const key = window.location.hostname;
const root = document.documentElement;

root?.style.setProperty(
  "--main_color",
  config["themes"][config["domains"][key]].colors.main
);
root?.style.setProperty(
  "--bg_color",
  config["themes"][config["domains"][key]].colors.background
);
root?.style.setProperty(
  "--navbar_icon_color",
  config["themes"][config["domains"][key]].colors.navbar_icon
);
root?.style.setProperty(
  "--navbar_icon_active_color",
  config["themes"][config["domains"][key]].colors.navbar_icon_active
);
root?.style.setProperty(
  "--box_color",
  config["themes"][config["domains"][key]].colors.box
);
root?.style.setProperty(
  "--separator_color",
  config["themes"][config["domains"][key]].colors.separator
);
root?.style.setProperty(
  "--background_image",
  "url(" + config["themes"][config["domains"][key]].background + ")"
);
root?.style.setProperty(
  "--secondary_color",
  config["themes"][config["domains"][key]].colors.secondary
);
root?.style.setProperty(
  "--button_color",
  config["themes"][config["domains"][key]].colors.button
);
root?.style.setProperty(
  "--button_border_color",
  config["themes"][config["domains"][key]].colors.button_border
);
root?.style.setProperty(
  "--button_txt_color",
  config["themes"][config["domains"][key]].colors.button_txt
);
root?.style.setProperty(
  "--secondary_button_color",
  config["themes"][config["domains"][key]].colors.secondary_button
);
root?.style.setProperty(
  "--secondary_button_border_color",
  config["themes"][config["domains"][key]].colors.secondary_button_border
);
root?.style.setProperty(
  "--secondary_button_txt_color",
  config["themes"][config["domains"][key]].colors.secondary_button_txt
);
root?.style.setProperty(
  "--text_color",
  config["themes"][config["domains"][key]].colors.text
);

root?.style.setProperty(
  "--progressbar",
  config["themes"][config["domains"][key]].colors.progressbar
);

root?.style.setProperty(
  "--font_family",
  config["themes"][config["domains"][key]].colors.font_family
);

root?.style.setProperty(
  "--font_path",
  "url(" + config["themes"][config["domains"][key]].colors.font_path + ")"
);

document.title =
  config["themes"][config["domains"][key]].company + " | Tokenization Platform";

const link = document.querySelector('link[rel="icon"]');

if (link) {
  console.log(config["themes"][config["domains"][key]].favicon);

  link.setAttribute("href", config["themes"][config["domains"][key]].favicon);
}

document.favicon =
  config["themes"][config["domains"][key]].company + " | Tokenization Platform";

export const configSlice = createSlice({
  name: "config",
  initialState: {
    wallet: config["themes"][config["domains"][key]].wallet,
    logo: config["themes"][config["domains"][key]].logo,
    companyName: config["themes"][config["domains"][key]].company,
    cookieLink: config["themes"][config["domains"][key]].cookie_link,
    termsLink: config["themes"][config["domains"][key]].terms_link,
    privacyLink: config["themes"][config["domains"][key]].privacy_link,
    logoWidth: config["themes"][config["domains"][key]]["logo-width"],
    background: config["themes"][config["domains"][key]].background,
    colors: config["themes"][config["domains"][key]].colors,
  },
  reducers: {},
});

export default configSlice.reducer;
