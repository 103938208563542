import { useParams } from "react-router-dom";

const Payment = () => {
  const { status } = useParams();

  return (
    <div class="container-fluid">
      <div class="section_title">
        <div class="row header_container">
          <div class="col-12 header_container_item">
            <h4 class="wallet_info_txt bold">
              {status.toLowerCase() === "ok"
                ? "Pago recibido correctamente. En breves minutos recibirá sus tokens."
                : "Ha ocurrido un error en el pago. Vuelva a intentarlo o consulte con su banco."}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
