import { useEffect, useState } from 'react';

export function useIpfsReadJson(hash) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    const node = process.env.REACT_APP_IPFS_NODE;

    const storagePrefix = 'ipfs_cache_';
  
    const getCachedFile = (hash) => {
        const stored = localStorage.getItem(storagePrefix + hash);
        if (stored == null) {
        return null;
        }
        return JSON.parse(stored)['0'];
    }

    const setCachedFile = (hash, content) => {
        localStorage.setItem(storagePrefix + hash, JSON.stringify({'0': content}))
    }
    
    const getJsonFile = async (hash) => {
        const cached = getCachedFile(hash);
        if (cached) {
            return cached;
        }
        const file = await (await fetch(node + hash)).json();
        setCachedFile(hash, file);
        return file;
    }
    useEffect(() => {
        if (hash) {
            getJsonFile(hash).then((data) => {
                setData(data);
                setIsLoading(false);
            });
        }
    }, [hash]);
    
    return {
        isLoading,
        data,
    }
}