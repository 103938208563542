import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { useSelector } from "react-redux";
import Select from "../select/select";
import "./home.scss";
import SaleBox from "./sale-box/sale-box";

const Home = () => {
  const OrderBy = {
    OPEN: "Ventas abiertas",
    CLOSED: "Ventas finalizadas",
    FUTURE: "Próximas ventas",
  };

  const salesQuery = gql`
    query getSales($wallet: Bytes) {
      sales(where: { createdBy: $wallet }) {
        id
        token {
          id
        }
        paymentToken
        whitelist
        _startDate
        _endDate
        _softCap
        _hardCap
        _price
        _saleInfo
        canceled
        paused
        createdBy
        createdAt
        updatedAt
        token {
          id
          name
          category
        }
      }
    }
  `;

  const wallet = useSelector((state) => state.config.wallet);

  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState("");

  const changeOrder = (option) => {
    setOrder(option);
  };

  let { data, loading } = useQuery(salesQuery, {
    variables: {
      wallet: wallet.toLowerCase(),
    },
  });

  let sales = data?.sales;

  if (sales) {
    if (order === OrderBy.OPEN) {
      sales = sales.filter(
        (sale, i) =>
          Date.now() < sale._endDate * 1000 &&
          Date.now() > sale._startDate * 1000
      );
    } else if (order === OrderBy.CLOSED) {
      sales = sales.filter((sale, i) => Date.now() > sale._endDate * 1000);
    } else if (order === OrderBy.FUTURE) {
      sales = sales.filter((sale, i) => Date.now() < sale._startDate * 1000);
    } else {
      sales = sales;
    }

    if (searchValue) {
      sales = sales.filter(
        (sale, i) =>
          sale.token.name.toLowerCase().indexOf(searchValue.toLowerCase()) !==
          -1
      );
    }
  }

  return (
    <>
      <div className="container pb-4 pt-4">
        <div className="section_title">
          <div className="row header_container">
            <div className="col-12 header_container_item">
              <h4 className="wallet_info_txt">
                Listado de oportunidades disponibles para inversión.
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12 header_container_item pt-3">
              <input
                onChange={(e) => setSearchValue(e.target.value)}
                title={"Buscar"}
                placeholder={"Buscar Ventas"}
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-6 col-12 header_container_item pt-3">
              <Select
                title={"Ordenar Por"}
                items={[OrderBy.OPEN, OrderBy.CLOSED, OrderBy.FUTURE]}
                onChange={(value) => changeOrder(value)}
                defaultValue={"Ordenar Por"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {loading && (
          <p>
            <strong>Cargando...</strong>
          </p>
        )}
        {!loading && (
          <div className="row">
            {sales.map((sale) => {
              if (sale.canceled || sale.paused) {
                return;
              } else {
                return (
                  <SaleBox
                    key={sale.id}
                    id={sale.id}
                    name={sale.token.name}
                    token={sale.token.id}
                    price={sale._price}
                    hardcap={sale._hardCap}
                    endDate={new Date(
                      sale._endDate * 1000
                    ).toLocaleDateString()}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
