import ProgressBar from "@ramonak/react-progress-bar";
import { useSelector } from "react-redux";
import "./progress-bar.scss";

const CustomProgressBar = ({ progress }) => {
  const bgColor = useSelector((state) => state.config.colors.progressbar);

  return (
    <div className="row justify-center pt-2 pb-4">
      <div className="col-12">
        <ProgressBar
          labelAlignment="center"
          className="progress_bar_wrapper"
          barContainerClassName="progress_bar_container"
          animateOnRender={true}
          completed={progress}
          bgColor={bgColor}
          height="35px"
          labelClassName={progress > 15 ? "progress_bar_label" : ""}
          isLabelVisible={progress > 15 ? true : false}
          customLabel={progress < 100 ? progress + "%" : "VENTA COMPLETA"}
        />
      </div>
    </div>
  );
};

export default CustomProgressBar;
