import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useAccount } from "wagmi";
import { setShowLogin, setShowProfile } from "../../states/wallet";
import PerfilLogin from "../perfil-login/perfil-login";
import Profile from "../profile/profile";
import "./navbar.scss";

const Navbar = () => {
  const dispatch = useDispatch();
  const { isConnected } = useAccount();

  const showLogin = useSelector((state) => state.wallet.showLogin);
  const showProfile = useSelector((state) => state.wallet.showProfile);
  const logo = useSelector((state) => state.config.logo);
  const logoWidth = useSelector((state) => state.config.logoWidth);

  const location = useLocation();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "var(--bg_color)",
    },
  };

  const openProfile = () => {
    if (!isConnected) {
      dispatch(setShowLogin(true));
      return;
    }
    dispatch(setShowProfile(true));
  };

  return (
    <div className="container">
      <div className="row navbar pb-4">
        <div className="col-xl-4 col-lg-4 col-md-4 col-12 pt-4 pb-2 justify-sm-center-and-lg-start">
          <a href="/">
            <img width={logoWidth} src={logo} alt="" />
          </a>
        </div>
        <div className="col-xl-7 col-lg-8 col-md-8 col-12 pt-4 pb-2">
          <div className="container">
            <div className="row justify-sm-center-and-lg-end">
              <Link
                className={
                  "navbar-badge " +
                  (location.pathname === "/home" ||
                  location.pathname === "/" ||
                  location.pathname.split("/")[1] === "sale"
                    ? "col-5 col-lg-3 active"
                    : "col-1 col-lg-1")
                }
                to="/"
              >
                <img
                  className={
                    location.pathname === "/home" ||
                    location.pathname === "/" ||
                    location.pathname.split("/")[1] === "sale"
                      ? "navbar-icon selected"
                      : "navbar-icon"
                  }
                  src="/images/home-icon.svg"
                  alt="Home Icon"
                />
                <span
                  className={
                    "navbar-icon-txt " +
                    (location.pathname === "/home" ||
                    location.pathname === "/" ||
                    location.pathname.split("/")[1] === "sale"
                      ? "selected"
                      : "")
                  }
                >
                  Ventas
                </span>
              </Link>
              <Link
                className={
                  "navbar-badge " +
                  (location.pathname === "/profile" ||
                  location.pathname.split("/")[1] === "token"
                    ? "col-5 col-lg-3 active"
                    : "col-1 col-lg-1")
                }
                to="/profile"
              >
                <img
                  className={
                    location.pathname === "/profile" ||
                    location.pathname.split("/")[1] === "token"
                      ? "navbar-icon selected"
                      : "navbar-icon"
                  }
                  src="/images/pie-icon.svg"
                  alt="Pie Icon"
                />
                <span
                  className={
                    "navbar-icon-txt " +
                    (location.pathname === "/profile" ||
                    location.pathname.split("/")[1] === "token"
                      ? "selected"
                      : "")
                  }
                >
                  Mis Activos
                </span>
              </Link>
              {/*<Link className="navbar-badge col-1 col-lg-1">
                <img
                  className="navbar-icon"
                  src="/images/data-icon.svg"
                  alt="Data Icon"
                />
                <span className="navbar-icon-txt">Histórico</span>
              </Link>
              <Link className="navbar-badge col-1 col-lg-1">
                <img
                  className="navbar-icon"
                  src="/images/star-icon.svg"
                  alt="Star Icon"
                />
                <span className="navbar-icon-txt">Favoritos</span>
                </Link>*/}
              <Link
                className="navbar-badge col-1 col-lg-1"
                onClick={() => openProfile()}
              >
                <img
                  className="navbar-icon"
                  src="/images/profile-icon.svg"
                  alt="Profile Icon"
                />
                <span className="navbar-icon-txt">Mi Perfil</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showProfile}
        ariaHideApp={false}
        onRequestClose={() => dispatch(setShowProfile(false))}
        contentLabel="Profile"
        style={customStyles}
      >
        <Profile></Profile>
      </Modal>

      <Modal
        isOpen={showLogin}
        ariaHideApp={false}
        onRequestClose={() => dispatch(setShowLogin(false))}
        contentLabel="Login Modal"
        style={customStyles}
      >
        <PerfilLogin></PerfilLogin>
      </Modal>
    </div>
  );
};

export default Navbar;
