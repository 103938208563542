import { ethers } from "ethers";
import Modal from "react-modal";

const SwitchNetworkModal = ({show}) => {
  const changeNetwork = async () => {
    const hexChainId = ethers.utils.hexValue(Number(process.env.REACT_APP_CHAIN_ID));
    if (window.ethereum) {
      try {
        await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        });
      } catch (errorSwitching) {
        alert('Error cambiando de red automáticamente');
        return;
      }
    }
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "var(--bg_color)",
    },
  };

  return (
    <Modal
    isOpen={true}
    style={customStyles}
    >
      <div className="row">
        <div className="col-12 text-center mt-3">
          <div className="row">
            <div className="col-12">
              <h5 className="white">
                Wallet conectado a una red incorrecta.
              </h5>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <button
                  type="button-light"
                  className="home_item_card_button"
                  onClick={changeNetwork}
                >Cambiar de red</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SwitchNetworkModal;
