import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./slider.scss";

const Slider = ({ images }) => {
  const ipfsNode = process.env.REACT_APP_IPFS_NODE;

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "450px",
    zIndex: "0"
  };

  return (
    <div className="slide-container">
      <Slide>
        {images.map((slideImage, index) => (
          <div key={index}>
            <div
              style={{
                ...divStyle,
                backgroundImage: `url(${ipfsNode + slideImage})`,
              }}
            ></div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default Slider;
