import { createSlice } from "@reduxjs/toolkit";
import { disconnect, readContract } from "@wagmi/core";
import Whitelist from "../abis/AtyumUsersWhitelist.json";

const signStoreKey = "atyum-signature";

export const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    showBalance: false,
    showLogin: false,
    showProfile: false,
    signature: localStorage.getItem(signStoreKey)
      ? JSON.parse(localStorage.getItem(signStoreKey))
      : {
          signature: null,
          timestampMs: null,
          signer: null,
        },
    kycStatus: null,
    userEmail: null,
  },
  reducers: {
    setShowBalance: (state, action) => {
      state.showBalance = action.payload;
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload;
    },
    setShowProfile: (state, action) => {
      state.showProfile = action.payload;
    },
    setSignature: (state, action) => {
      state.signature.signature = action.payload.signature;
      state.signature.timestampMs = action.payload.timestampMs;
      state.signature.signer = action.payload.signer;
      localStorage.setItem(signStoreKey, JSON.stringify(action.payload));
    },
    setKycStatus: (state, action) => {
      state.kycStatus = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
  },
});

export const {
  setShowBalance,
  setShowLogin,
  setShowProfile,
  setSignature,
  setKycStatus,
  setUserEmail,
} = walletSlice.actions;

export function disconnectWallet(dispatch, getState) {
  localStorage.removeItem(signStoreKey);
  dispatch(setKycStatus(null));
  disconnect();
}

export function refreshKycStatus(connectedWallet) {
  return async function (dispatch, getState) {
    const state = getState();
    if (!state.wallet.signature.signature) {
      return;
    }

    if (state.wallet.signature.signer !== connectedWallet) {
      localStorage.removeItem(signStoreKey);
    }

    const yesterdayMs = Date.now() - 86400000 - 60 * 60 * 1000; // 23 hours ago

    if (
      state.wallet.signature.timestampMs < yesterdayMs ||
      state.wallet.signature.signer !== connectedWallet
    ) {
      dispatch(setKycStatus(null));
      disconnect();
      return;
    }

    fetch(process.env.REACT_APP_KYC_URL + "/kyc/status", {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify(state.wallet.signature),
    }).then(async (data) => {
      const res = await data.json();
      const status = res.status;
      if (status === "COMPLETED") {
        const whitelist = await readContract({
          address: process.env.REACT_APP_WHITELIST_ADDRESS,
          abi: Whitelist,
          functionName: "getWhitelistStatus",
          args: [connectedWallet],
        });
        dispatch(setUserEmail(res.email));
        if (whitelist === true) {
          dispatch(setKycStatus("COMPLETED_AND_SYNCED"));
          return;
        }
      }
      dispatch(setKycStatus(status));
    });
  };
}

export default walletSlice.reducer;
