import copy from "copy-to-clipboard";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import AtyumToken from "../../abis/AtyumToken.json";
import {
  disconnectWallet,
  setShowBalance,
  setShowProfile,
} from "../../states/wallet";
import "./profile.scss";

const Profile = () => {
  const dispatch = useDispatch();
  const { address, isConnected } = useAccount();

  const showBalance = useSelector((state) => state.wallet.showBalance);
  const logo = useSelector((state) => state.config.logo);
  const logoWidth = useSelector((state) => state.config.logoWidth);

  const [transferRecipient, setTransferRecipient] = useState("");
  const [transferAmount, setTransferAmount] = useState("0");
  const [showTransfer, setShowTransfer] = useState(false);

  const { data: balance } = useContractRead({
    address: process.env.REACT_APP_PAYMENT_TOKEN,
    abi: AtyumToken,
    functionName: "balanceOf",
    args: [address],
    enabled: address,
  });

  const handleInputTransferChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9\b]+$/;

    if (inputValue === "" || regex.test(inputValue)) {
      setTransferAmount(inputValue);
    }
  };

  const { config: transferConfig } = usePrepareContractWrite({
    address: process.env.REACT_APP_PAYMENT_TOKEN,
    abi: AtyumToken,
    functionName: "transfer",
    args: [
      transferRecipient,
      ethers.utils.parseUnits(
        transferAmount,
        process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS
      ),
    ],
  });

  const { write: transferWrite, data: transferData } =
    useContractWrite(transferConfig);

  const { isLoading: isTrasferLoading, isSuccess: isTransferSuccess } =
    useWaitForTransaction({
      hash: transferData?.hash,
    });

  useEffect(() => {
    if (isTransferSuccess) {
      toast.success("Transferido correctamente!");
    }
  }, [isTransferSuccess]);

  const disconnect = () => {
    dispatch(disconnectWallet);
    dispatch(setShowProfile(false));
    toast.success("Wallet desconectado!");
  };

  const copyWallet = () => {
    copy(address);
    toast.success("Wallet copiado al portapapeles!");
  };

  if (!isConnected) {
    return <></>;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row justify-center">
          <div className="col-12 justify-center pt-4 pb-4">
            <img src={logo} alt="" width={logoWidth} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 justify-center">
            <h4 className="wallet_info_txt text-center bold">Perfil</h4>
          </div>
        </div>

        <hr className="separator" />

        <div className="row">
          <div className="col-12 justify-center pb-3">
            <span className="wallet_info_txt">
              <span className="pointer" onClick={() => copyWallet()}>
                {address.substring(0, 10) +
                  "…" +
                  address.substring(address.length, address.length - 10)}
              </span>
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-12 justify-center pb-4">
            {showBalance ? (
              <span
                className=" "
                onClick={() => dispatch(setShowBalance(false))}
              >
                {balance
                  ? parseFloat(
                      ethers.utils.formatUnits(
                        balance.toString(),
                        process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS
                      )
                    ).toFixed(2) + " USDT"
                  : "0 USDT"}{" "}
                <img
                  className="pointer"
                  src="/images/eye-icon.png"
                  width={20}
                  alt=""
                />
              </span>
            ) : (
              <span
                className="pointer"
                onClick={() => dispatch(setShowBalance(true))}
              >
                Mostrar saldo{" "}
                <img src="/images/hide-eye-icon.png" width={20} alt="" />
              </span>
            )}
          </div>
        </div>

        {showTransfer && (
          <div className="row">
            <div className="col-12 mb-2">
              <span className="invest_txt">Cantidad de USDT</span>
            </div>
            <div className="col-12 mb-3">
              <div className="input_container">
                <input
                  type="text"
                  maxLength="11"
                  value={transferAmount}
                  onChange={handleInputTransferChange}
                  placeholder="Tokens"
                />
              </div>
            </div>
            <div className="col-12 mb-2">
              <span className="invest_txt">Wallet destino</span>
            </div>
            <div className="col-12 mb-3">
              <div className="input_container">
                <input
                  type="text"
                  value={transferRecipient}
                  onChange={(e) => {
                    setTransferRecipient(e.target.value);
                  }}
                  placeholder="0x...."
                />
              </div>
            </div>
            <div className="col-12 mb-3">
              <button
                className="connect_btn"
                onClick={() =>
                  window.confirm(
                    "Transferir " +
                      transferAmount +
                      " USDT a " +
                      transferRecipient +
                      "?"
                  )
                    ? transferWrite?.()
                    : null
                }
              >
                {!isTrasferLoading ? "Transferir" : ". . ."}
              </button>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12 mb-3">
            <button
              className="connect_btn"
              onClick={() => setShowTransfer(!showTransfer)}
            >
              {!showTransfer ? "Transferir saldo" : "Cancelar"}
            </button>
          </div>
          <div className="col-12 mb-3">
            <button className="connect_btn" onClick={() => disconnect()}>
              Desconectar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
