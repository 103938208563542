import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { useAccount, useContractReads } from "wagmi";
import AtyumToken from "../../abis/AtyumToken.json";
import Select from "../select/select";
import "./my-tokens.scss";
import Token from "./token/token";

const MyTokens = () => {
  const OrderBy = {
    CLAIMABLE: "Listo para reclamar",
    AMOUNT_DESC: "Mayor cantidad",
    AMOUNT_ASC: "Menor cantidad",
  };

  const [order, setOrder] = useState(OrderBy.AMOUNT_DESC);

  const [searchValue, setSearchValue] = useState("");
  const { address } = useAccount();

  const tokensQuery = gql`
    query getTokens($wallet: Bytes) {
      tokens {
        id
        name
        category
        tokenManager
        rewardToken
        createdAt
        updatedAt
      }
    }
  `;

  let { data, loading } = useQuery(tokensQuery, {
    variables: {},
  });

  let msgStatus = "";

  let tokenList = data?.tokens;

  let { data: tokensFromUser, loading: loadingUserTokens } = useContractReads({
    contracts: [
      ...(tokenList
        ? tokenList.map((token) => {
            return {
              address: token.id,
              abi: AtyumToken,
              functionName: "balanceOf",
              args: [address],
            };
          })
        : []),
    ],
    enabled: tokenList && address,
  });

  let { data: tokensWithPendingClaim, loading: loadingClaimableTokens } =
    useContractReads({
      contracts: [
        ...(tokenList
          ? tokenList.map((token) => {
              return {
                address: token.id,
                abi: AtyumToken,
                functionName: "withdrawableDividendsOf",
                args: [address],
              };
            })
          : []),
      ],
      enabled: tokenList && address,
    });

  if (
    loading ||
    loadingClaimableTokens ||
    loadingUserTokens ||
    !tokensWithPendingClaim ||
    !tokensFromUser
  ) {
    msgStatus = "Cargando...";
  }

  const changeFilter = (option) => {
    setOrder(option);
  };

  let filteredTokens = [];

  if (address && tokenList && tokensWithPendingClaim && tokensFromUser) {
    tokenList = tokenList.map((item) =>
      Object.assign({}, item, { claimAvailable: false, amount: 0 })
    );

    filteredTokens = tokenList.filter((token, i) => {
      tokenList[i].claimAvailable = parseInt(tokensWithPendingClaim[i]) > 0;
      tokenList[i].amount = parseInt(tokensFromUser[i]);
      if (
        parseInt(tokensWithPendingClaim[i]) > 0 ||
        parseInt(tokensFromUser[i]) > 0
      ) {
        return true;
      }
      return false;
    });

    if (order === OrderBy.CLAIMABLE) {
      filteredTokens = filteredTokens.filter(
        (token, i) => token.claimAvailable
      );
    } else if (order === OrderBy.AMOUNT_ASC) {
      filteredTokens.sort((a, b) => a.amount - b.amount);
    } else if (order === OrderBy.AMOUNT_DESC) {
      filteredTokens.sort((a, b) => b.amount - a.amount);
    }

    if (searchValue) {
      filteredTokens = filteredTokens.filter(
        (token, i) =>
          token.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
      );
    }
  }

  if (!address) {
    msgStatus = "Entra con tu cuenta para visualizar tus tokens.";
  }

  if (
    !loading &&
    !loadingUserTokens &&
    tokensWithPendingClaim &&
    tokensFromUser &&
    filteredTokens &&
    filteredTokens.length === 0 &&
    address
  ) {
    msgStatus = "No dispones de ningun activo en estos momentos.";
  }

  return (
    <>
      <div className="container pb-4 pt-4">
        <div className="section_title">
          <div className="row header_container">
            <div className="col-12 header_container_item">
              <h4 className="wallet_info_txt">
                Tu Lista de Tokens
                </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-12 header_container_item pt-3">
              <input
                onChange={(e) => setSearchValue(e.target.value)}
                title={"Buscar"}
                placeholder={"Buscar activos"}
              />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-6 col-12 header_container_item pt-3">
              <Select
                title={"Ordenar Por"}
                items={[
                  OrderBy.CLAIMABLE,
                  OrderBy.AMOUNT_DESC,
                  OrderBy.AMOUNT_ASC,
                ]}
                onChange={(value) => changeFilter(value)}
                defaultValue={"Ordenar Por"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pt-2">
          <div className="col-lg-12">
            {msgStatus && <strong>{msgStatus}</strong>}
          </div>
        </div>

        {address && filteredTokens && filteredTokens.length > 0 && (
          <div className="row">
            <div className="col-lg-12">
              <div className="container table_container pt-3 pb-5">
                <div className="separator_light mb-5"></div>
                <div className="table-responsive">
                  <table width="100%">
                    <thead>
                      <tr>
                        <th className="table_header_item light">
                          <span>Proyecto</span>
                        </th>
                        <th className="table_header_item light">
                          <span>Tipo de inversión</span>
                        </th>
                        <th className="table_header_item light">
                          <span>Rentabilidad Anualizada</span>
                        </th>
                        <th className="table_header_item light">
                          <span>N° tokens</span>
                        </th>
                        <th className="table_header_item light">
                          <span>Beneficios</span>
                        </th>
                        <th className="table_header_item">
                          <span></span>
                        </th>
                        <th className="table_header_item">
                          <span></span>
                        </th>
                      </tr>
                    </thead>

                    <div className="pb-4"></div>

                    <tbody>
                      {filteredTokens.map((token) => (
                        <Token
                          key={token.id}
                          id={token.id}
                          name={token.name}
                          category={token.category}
                          claimAvailable={token.claimAvailable}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="separator_light mt-3"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyTokens;
