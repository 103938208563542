import { readContract } from "@wagmi/core";
import { useState } from "react";
import AtyumTokenABI from "../abis/AtyumToken.json";

export function useIsSaleOpen(token) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaleOpen, setIsSaleOpen] = useState(false);

  readContract({
    address: token,
    abi: AtyumTokenABI,
    functionName: "pointsPerShare",
  }).then((data) => {
    if (data.toString() > 0) {
      setIsSaleOpen(false);
    } else {
      setIsSaleOpen(true);
    }

    setIsLoading(false);
  });

  return {
    isLoading,
    data: isSaleOpen,
  };
}
