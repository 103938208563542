import { ethers } from "ethers";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount, useContractRead, useContractReads } from "wagmi";
import AtyumToken from "../../abis/AtyumToken.json";
import AtyumTokenSale from "../../abis/AtyumTokenSale.json";
import { useIpfsReadJson } from "../../hooks/use-ipfs-read-json";
import "./my-token-details.scss";
import Kyc from "../kyc/kyc";
import Description from "../description/description";
import Claim from "../claim/claim";
import { gql, useQuery } from "@apollo/client";
import CustomProgressBar from "../progress-bar/progress-bar";
import { useEffect, useState } from "react";
import Chart from "../chart/chart";
import config from "../../config.json";

const MyTokenDetails = () => {
  const salesQuery = gql`
    query getSales($wallet: Bytes) {
      sales(first: 1, orderBy: _endDate, orderDirection: desc) {
        id
        token {
          id
        }
        whitelist
        _startDate
        _endDate
        _softCap
        _hardCap
        _price
        canceled
      }
    }
  `;

  const navigate = useNavigate();
  const [eurConversion, setEurConversion] = useState();

  const { id } = useParams();

  const ipfsNode = process.env.REACT_APP_IPFS_NODE;
  const { address } = useAccount();
  useEffect(() => {
    fetch(process.env.REACT_APP_KYC_URL + "/payments/rate")
      .then((res) => res.json())
      .then((data) => {
        setEurConversion(data.rate);
      });
  }, []);

  let { data: saleList } = useQuery(salesQuery);

  const { data: saleData } = useContractRead({
    address: saleList?.sales[0].id,
    abi: AtyumTokenSale,
    functionName: "saleData",
    enabled: saleList,
  });

  let progress = 0;

  if (saleData) {
    progress = (
      Math.ceil((parseFloat(
        ethers.utils.formatUnits(saleData.totalMintedBySale.toString(), 18)
      ) *
        100) /
        parseFloat(ethers.utils.formatUnits(saleData.hardCap.toString(), 18))
    )).toFixed(2);
  }

  const convertToEur = config["themes"][config["domains"][window.location.hostname]].eurConversion;
  let tokenPriceInEUR = 0;
  let tokenPriceInUSD = 0;

  if (saleData && saleData.price) {
    tokenPriceInUSD = parseFloat(
      ethers.utils.formatUnits(
        saleData.price
          .mul(1 * 10 ** parseInt(process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS))
          .toString(),
        18
      )
    );
    tokenPriceInEUR = 1 / tokenPriceInUSD * eurConversion;
    tokenPriceInUSD = 1 / tokenPriceInUSD;
  }

  const { data } = useContractReads({
    contracts: [
      {
        address: id,
        abi: AtyumToken,
        functionName: "tokenInfo",
      },
      {
        address: id,
        abi: AtyumToken,
        functionName: "withdrawableDividendsOf",
        args: [address],
      },
      {
        address: id,
        abi: AtyumToken,
        functionName: "balanceOf",
        args: [address],
      },
      {
        address: id,
        abi: AtyumToken,
        functionName: "name",
      },
      {
        address: id,
        abi: AtyumToken,
        functionName: "category",
      },
    ],
    enabled: address,
  });

  const { data: info } = useIpfsReadJson(data ? data[0] : null);

  return (
    <>
      <div className="container pb-4 pt-4">
        <div className="section_title">
          <div className="row header_container">
            <div className="col-xl-10 col-lg-6 col-md-5 col-12 header_container_item">
              <h4 className="wallet_info_txt">
                <span
                  className="my_sale_details_link_back"
                  onClick={() => navigate(-1)}
                >
                  <img height={11} src="/images/back-arrow-icon.png" alt="" />{" "}
                  Volver
                </span>
              </h4>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-5 col-12 header_container_item end"></div>
          </div>
        </div>
      </div>

      <div className="container mt-4 mb-5">
        <div className="see_sale_detail_card">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-7 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="row pb-4 justify-center">
                    <div className="my_sale_detail_card_graph_container center">
                      <Chart></Chart>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col my_sale_detail_card_img_container">
                  <img
                    className="my_sale_detail_card_img top_img"
                    src={info ? ipfsNode + info.images[0] : ""}
                    alt=""
                  />
                </div>
                {info && info.images[1] && (
                  <div className="col my_sale_detail_card_img_container">
                    <img
                      className="my_sale_detail_card_img top_img"
                      src={info ? ipfsNode + info.images[1] : ""}
                      alt=""
                    />
                  </div>
                )}
                {info && info.images[2] && (
                  <div className="col my_sale_detail_card_img_container">
                    <img
                      className="my_sale_detail_card_img top_img"
                      src={info ? ipfsNode + info.images[2] : ""}
                      alt=""
                    />
                  </div>
                )}
                {info && info.images[3] && (
                  <div className="col my_sale_detail_card_img_container">
                    <img
                      className="my_sale_detail_card_img top_img"
                      src={info ? ipfsNode + info.images[3] : ""}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-xl-5 col-lg-5 col-md-5 col-12">
              <div className="row">
                <div className="col-lg-12">
                  <p className="see_sale_detail_card_name">
                    {data ? data[3] : "..."}
                  </p>
                </div>
              </div>

              <div className="row justify-center">
                <div className="col-6 pt-3 justify-sm-center-and-lg-start block-bigger">
                  <a
                    href={info ? ipfsNode + info.whitepaper : void 0}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="see_sale_detail_card_whitepaper">
                      Ver Whitepaper
                    </span>
                  </a>
                </div>

                <div className="col-6 pt-3 justify-sm-center-and-lg-end block-bigger">
                  <Kyc />
                </div>
              </div>

              <div className="row pt-4 pb-3">
                {id && (
                  <a
                    href={
                      process.env.REACT_APP_BLOCKCHAIN_EXPLORER +
                      "address/" +
                      id
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="token_address_txt">
                      Token:{" "}
                      {id.substring(0, 7) +
                        "…" +
                        id.substring(id.length, id.length - 7)}
                      <img
                        className="pl-3 redirect-logo"
                        src="/images/redirect-icon.png"
                        width={12}
                        alt=""
                      />
                    </span>
                  </a>
                )}
              </div>

              <Description text={info ? info.description : ""}></Description>

              <div className="row pt-2">
                <div className="col-lg-12">
                  <CustomProgressBar progress={progress} />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-6">
                  <p className="see_sale_detail_card_totalraise_txt">
                    Financiación Total
                  </p>
                  <p className="see_sale_detail_card_totalraise">
                    <span>
                      {saleData &&
                        (
                          parseFloat(
                            ethers.utils.formatUnits(saleData.hardCap, 18)
                          ) * (convertToEur ? tokenPriceInEUR : tokenPriceInUSD)
                        ).toLocaleString("es-ES", {
                          maximumFractionDigits: 2,
                        })}
                      {convertToEur ? '€' : '$'}
                    </span>
                  </p>
                </div>

                <div className="col-6">
                  <p className="see_sale_detail_card_price_txt">
                    Precio Por 1 Token
                  </p>
                  <p className="see_sale_detail_card_price">
                    <span>
                      {(convertToEur ? tokenPriceInEUR : tokenPriceInUSD).toLocaleString("es-ES", {
                        maximumFractionDigits: 0,
                      })}
                      {convertToEur ? '€' : '$'}
                    </span>
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p className="see_sale_detail_card_date_txt">Fecha Final</p>
                  <p className="see_sale_detail_card_date">
                    <span>
                      {saleData &&
                        new Date(saleData.endDate * 1000).toLocaleDateString()}
                    </span>
                  </p>
                </div>
                <div className="col-6">
                  <p className="see_sale_detail_card_apr_txt">Rentabilidad Anualizada</p>
                  <p className="see_sale_detail_card_apr">
                    <span>{info ? info.apr : "..."}%</span>
                  </p>
                </div>
              </div>

              <div className="row mt-4">
                <p className="see_sale_detail_card_date_txt">Inversión</p>
                <p className="see_sale_detail_card_date">
                  <span>
                    {data && data[2]
                      ? ethers.utils.formatUnits(data[2], 18)
                      : "..."}{" "}
                    Tokens
                  </span>
                </p>
              </div>

              <div className="row mt-3">
                <p className="see_sale_detail_card_date_txt">Beneficios</p>
                <p className="col-12 my_sale_detail_card_date">
                  <span className="primary">
                    $
                    {data && data[1]
                      ? parseFloat(
                          ethers.utils.formatUnits(data[1], 18)
                        ).toFixed(2)
                      : "..."}
                  </span>
                </p>
              </div>

              <div className="row  justify-center mt-3">
                <div className="col-6 justify-center">
                  <Claim
                    address={id}
                    claimAvailable={data && data[1] && parseInt(data[1] > 0)}
                  ></Claim>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyTokenDetails;
