import { useRef } from "react";
import "./select.scss";

const Select = ({ title, items, onChange, defaultValue }) => {
  const getRadioCheckedValue = (e) => {
    const { value } = e.target;
    onChange(value);
  };

  const parentInput = useRef(null);

  return (
    <div className="select-box" ref={parentInput} tabIndex="1">
      <div className="select-box__current">
        <div className="select-box__value">
          <input
            className="select-box__input"
            type="radio"
            id="default"
            value="default"
            defaultChecked="checked"
            name={title}
          />

          <p className="select-box__input-text">
            {defaultValue ? defaultValue : title}
          </p>
        </div>

        {items.map((item, i) => {
          return (
            <div key={i} className="select-box__value">
              <input
                className="select-box__input"
                type="radio"
                id={title + "option" + i}
                value={item}
                name={title}
                onChange={getRadioCheckedValue}
              />
              <p className="select-box__input-text">{item}</p>
            </div>
          );
        })}

        <img
          className="select-box__icon"
          src="/images/select-arrow.png"
          alt="Arrow Icon"
          aria-hidden="true"
        />
      </div>

      <ul
        className="select-box__list"
        onClick={(e) => parentInput.current.blur()}
      >
        {items.map((item, i) => {
          return (
            <li key={i}>
              <label
                className="select-box__option"
                htmlFor={title + "option" + i}
                aria-hidden="true"
              >
                {item}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Select;
