import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import { useAccount, useContractReads } from "wagmi";
import AtyumToken from "../../../abis/AtyumToken.json";
import categories from "../../../categories.json";
import { useIpfsReadJson } from "../../../hooks/use-ipfs-read-json";
import Claim from "../../claim/claim";
import "./token.scss";

const Token = ({ id, name, category, claimAvailable }) => {
  const navigate = useNavigate();

  const seeSale = () => {
    navigate({
      pathname: `/token/${id}`,
    });
  };

  const { address } = useAccount();

  const { data } = useContractReads({
    contracts: [
      {
        address: id,
        abi: AtyumToken,
        functionName: "tokenInfo",
      },
      {
        address: id,
        abi: AtyumToken,
        functionName: "withdrawableDividendsOf",
        args: [address],
      },
      {
        address: id,
        abi: AtyumToken,
        functionName: "balanceOf",
        args: [address],
      },
      {
        address: id,
        abi: AtyumToken,
        functionName: "totalSupply",
      },
    ],
    enabled: address,
  });

  const { data: info } = useIpfsReadJson(data ? data[0] : null);

  return (
    <tr>
      <td className="table_item">
        <span className="bold table_content-txt" title={name}>
          {name}
        </span>
      </td>
      <td className="table_item">
        <span className="table_content-txt" title={categories[category]}>
          {categories[category]}
        </span>
      </td>
      <td className="table_item">
        <span className="bold table_content-txt" title="Rentabilidad anualizada">
          {info ? info.apr + "%" : "..."}
        </span>
      </td>
      <td className="table_item">
        <span className="bold table_content-txt" title="Tokens">
          {data
            ? parseInt(ethers.utils.formatUnits(data[2], 18))
                .toFixed(2)
                .replace(/\.00$/, "")
            : "..."}
          /
          {data
            ? parseInt(ethers.utils.formatUnits(data[3], 18))
                .toFixed(2)
                .replace(/\.00$/, "")
            : "..."}
        </span>
      </td>
      <td className="table_item">
        <span className="bold table_content-txt rewards" title="Beneficios">
          {data && data[1]
            ? "$" +
              parseFloat(
                ethers.utils.formatUnits(
                  data[1],
                  process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS
                )
              ).toLocaleString("es-ES", {
                maximumFractionDigits: 2,
              })
            : "..."}
        </span>
      </td>
      <td className="table_item_btn">
        <Claim address={id} claimAvailable={claimAvailable}></Claim>
      </td>
      {/* <td className="table_item_btn">
        <button
          onClick={() => seeSale()}
          className="item_card_button_secondary"
        >
          Estadisticas
        </button>
      </td> */}
    </tr>
  );
};

export default Token;
