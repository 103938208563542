import { useSelector } from "react-redux";
import "./loading.scss";

function Loading() {
  const isLoading = useSelector((state) => state.loading.isLoading);

  if (!isLoading) {
    return <></>;
  }

  return (
    <div className="loading-bg">
      <div>
        <img src="/images/loading.svg"></img>
      </div>
    </div>
  );
}

export default Loading;
