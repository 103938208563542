import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContractRead } from "wagmi";
import AtyumTokenSale from "../../../abis/AtyumTokenSale.json";
import config from "../../../config.json";
import { useIpfsReadJson } from "../../../hooks/use-ipfs-read-json";
import { useIsSaleOpen } from "../../../hooks/use-is-sale-open";
import CustomProgressBar from "../../progress-bar/progress-bar";
import "./sale-box.scss";

const SaleBox = ({ id, name, token, price, hardcap, endDate }) => {
  const navigate = useNavigate();

  const [favorite, setFavorite] = useState(false);
  const [eurConversion, setEurConversion] = useState();
  const convertToEur =
    config["themes"][config["domains"][window.location.hostname]].eurConversion;

  let progress = 0;

  const ipfsNode = process.env.REACT_APP_IPFS_NODE;

  price = parseFloat(
    ethers.utils.formatUnits(
      BigNumber.from(price + "")
        .mul(1 * 10 ** parseInt(process.env.REACT_APP_PAYMENT_TOKEN_DECIMALS))
        .toString(),
      18
    )
  );
  let tokenPriceInEUR = 0;
  let tokenPriceInUSD = 0;
  if (eurConversion) {
    tokenPriceInEUR = (1 / price) * eurConversion;
    tokenPriceInUSD = 1 / price;
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_KYC_URL + "/payments/rate")
      .then((res) => res.json())
      .then((data) => {
        setEurConversion(data.rate);
      });
  }, []);

  price = 1 / price / eurConversion;

  const { data } = useContractRead({
    address: id,
    abi: AtyumTokenSale,
    functionName: "saleData",
  });

  if (data) {
    progress = Math.ceil(
      (parseFloat(
        ethers.utils.formatUnits(data.totalMintedBySale.toString(), 18)
      ) *
        100) /
        parseFloat(ethers.utils.formatUnits(data.hardCap.toString(), 18))
    ).toFixed(2);
  }
  const { data: info } = useIpfsReadJson(data ? data.saleInfo : null);

  if (info) {
    const regex = /(<([^>]+)>)/gi;
    info.description = info.description.replace(regex, "");
  }

  const seeSale = () => {
    navigate({
      pathname: `/sale/${id}`,
    });
  };

  const { data: isSaleOpen, isLoading: isSaleOpenLoading } =
    useIsSaleOpen(token);

  if (!isSaleOpenLoading && !isSaleOpen) {
    return;
  }

  if (!isSaleOpenLoading && isSaleOpen) {
    return (
      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-12 mt-4 mb-5">
        <div className="home_item_card">
          <div className="container">
            <div className="row">
              <div className="col p-0 center">
                <div className="img_container">
                  <img
                    className="home_item_card_image"
                    src={info && info.images ? ipfsNode + info.images[0] : ""}
                    alt=""
                  />
                  {favorite ? (
                    <img
                      onClick={() => setFavorite(!favorite)}
                      className="fav_icon"
                      src="/images/fav-icon-filled.png"
                      alt="filled"
                    />
                  ) : (
                    <img
                      onClick={() => setFavorite(!favorite)}
                      className="fav_icon"
                      src="/images/fav-icon-empty.png"
                      alt="empty"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="home_item_card_title_container">
                  <span className="home_item_card_title">{name}</span>
                </div>
              </div>
            </div>

            <div className="row pt-1">
              <div className="col-lg-12">
                <div className="home_item_card_description">
                  {info && info.description.substring(0, 230)}...
                </div>
              </div>
            </div>

            <div className="row justify-center">
              <div className="col-lg-11">
                <CustomProgressBar progress={progress} />
              </div>
            </div>

            <div className="row text-center">
              <div className="col-6">
                <span className="home_item_card_totalraise_txt">
                  Financiación Total
                </span>
                <p className="home_item_card_totalraise">
                  {(
                    parseFloat(ethers.utils.formatUnits(hardcap, 18)) *
                    (convertToEur ? tokenPriceInEUR : tokenPriceInUSD)
                  ).toLocaleString("es-ES", {
                    maximumFractionDigits: 0,
                  })}
                  {convertToEur ? "€" : "$"}
                </p>
              </div>
              <div className="col-6">
                <span className="home_item_card_price_txt">
                  Precio Por 1 Token
                </span>
                <p className="home_item_card_price">
                  {(convertToEur
                    ? tokenPriceInEUR
                    : tokenPriceInUSD
                  ).toLocaleString("es-ES", {
                    maximumFractionDigits: 2,
                  })}
                  {convertToEur ? "€" : "$"}
                </p>
              </div>
            </div>

            <div className="row text-center">
              <div className="col-6">
                <span className="home_item_card_enddate_txt">
                  Fecha Finalización
                </span>
                <p className="home_item_card_enddate">{endDate}</p>
              </div>
              <div className="col-6 grid_center">
                <span className="home_item_card_apr_txt">
                  Rentabilidad Anualizada
                </span>
                <p className="home_item_card_apr">{info ? info.apr : "..."}%</p>
              </div>
            </div>

            <div className="row">
              <div className="col center home_item_card_hover-box">
                <button
                  className="home_item_card_button"
                  onClick={() => seeSale()}
                >
                  Ver Venta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SaleBox;
