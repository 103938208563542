import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./footer.scss";

const Footer = () => {
  const companyName = useSelector((state) => state.config.companyName);
  const cookieLink = useSelector((state) => state.config.cookieLink);
  const termsLink = useSelector((state) => state.config.termsLink);
  const privacyLink = useSelector((state) => state.config.privacyLink);

  return (
    <div className="container">
      <div className="row pb-5 pt-2">
        <div className="col-xl-5 col-lg-5 col-md-5 col-12 pt-4 pb-2 justify-sm-center-and-lg-start">
          <div className="row">
            <div className="col-lg-12 pt-3">
              <span>© 2023 {companyName} | All rights reserved</span>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-lg-7 col-md-7 col-12 pt-4 pb-2 row justify-sm-center-and-lg-end">
          <div className="container">
            <div className="row">
              <Link className="col-lg-4 justify-center pt-3" to={cookieLink} target="_blank">
                <span className="footer-link">Cookies</span>
              </Link>
              <Link className="col-lg-4 justify-center pt-3" to={termsLink} target="_blank">
                <span className="footer-link">Terminos y condiciones</span>
              </Link>
              <Link className="col-lg-4 justify-center pt-3" to={privacyLink} target="_blank">
                <span className="footer-link">Política de privacidad</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
