import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import AtyumToken from "../../abis/AtyumToken.json";
import { useInvalidNetwork } from "../../hooks/use-invalid-network";
import { setIsLoading } from "../../states/loading";
import SwitchNetworkModal from "../switch-chain/switch-network-modal";
import "./claim.scss";

const Claim = ({ address, claimAvailable }) => {
  const dispatch = useDispatch();

  const { config: claimConfig } = usePrepareContractWrite({
    address: address,
    abi: AtyumToken,
    functionName: "withdrawRewards",
    args: [],
  });

  const { write: claimWrite, data: claimData } = useContractWrite(claimConfig);

  const {
    isLoading: isClaimLoading,
    isSuccess: isClaimSuccess,
    isError: isClaimError,
  } = useWaitForTransaction({
    hash: claimData?.hash,
  });

  useEffect(() => {
    if (isClaimLoading) {
      dispatch(setIsLoading(true));
    } else {
      dispatch(setIsLoading(false));
    }
  }, [isClaimLoading]);

  useEffect(() => {
    if (isClaimSuccess) {
      toast.success("Reclamado correctamente!");
    }
  }, [isClaimSuccess]);

  useEffect(() => {
    if (isClaimError) {
      toast.error("No se ha podido reclamar la comisión.");
    }
  }, [isClaimError]);

  const invalidChain = useInvalidNetwork();

  if (claimAvailable && invalidChain) {
    return <SwitchNetworkModal></SwitchNetworkModal>;
  }

  return (
    <>
      {claimAvailable ? (
        <button
          onClick={() => claimWrite?.()}
          disabled={!claimWrite}
          className="item_card_button"
        >
          {isClaimLoading ? "Reclamando..." : "Reclamar"}
        </button>
      ) : (
        <button className="item_card_button disabled">Reclamar</button>
      )}
    </>
  );
};

export default Claim;
