import { SequenceConnector } from "@0xsequence/wagmi-connector";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { EthereumClient } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { WagmiConfig, configureChains, createClient } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import Loading from "./components/loading/loading";
import MyTokens from "./components/my-sales/my-tokens";
import MyTokenDetails from "./components/my-token-details/my-token-details";
import Navbar from "./components/navbar/navbar";
import Payment from "./components/payment/payment";
import SeeSaleDetails from "./components/see-sale-details/see-sale-details";
import "./index.scss";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const projectId = process.env.REACT_APP_PROJECT_ID;

if (!projectId) {
  throw new Error("You need to provide REACT_APP_PROJECT_ID env variable");
}

const atyumChain = {
  id: Number(process.env.REACT_APP_CHAIN_ID),
  name: process.env.REACT_APP_CHAIN_NAME,
  network: process.env.REACT_APP_CHAIN_NAME,
  nativeCurrency: {
    name: process.env.REACT_APP_CURRENCY,
    symbol: process.env.REACT_APP_CURRENCY,
    decimals: 18,
  },
  rpcUrls: {
    default: process.env.REACT_APP_RPC,
  },
  testnet: false,
  multicall: {
    address: process.env.REACT_APP_MULTICALL_ADDRESS,
    blockCreated: process.env.REACT_APP_MULTICALL_BLOCK_CREATED,
  },
};

const { chains, provider } = configureChains(
  [atyumChain],
  [
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id !== atyumChain.id) return null;
        return { http: chain.rpcUrls.default };
      },
    }),
  ]
);

const connectors = [
  new SequenceConnector({
    chains,
    options: {
      connect: {
        app: "Atyum",
        networkId: Number(process.env.REACT_APP_CHAIN_ID),
        settings: {
          theme: "dark",
          bannerUrl: "https://i.ibb.co/BnhJkN7/atyum-banner.png",
          includedPaymentProviders: ["moonpay"],
        },
      },
    },
  }),
  new MetaMaskConnector({ chains }),
  new WalletConnectConnector({
    chains,
    options: {
      projectId,
      showQrModal: true,
    },
  }),
];

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const ethereumClient = new EthereumClient(wagmiClient, chains);

const apolloCache = new InMemoryCache();
const graphqlClient = new ApolloClient({
  cache: apolloCache,
  uri: process.env.REACT_APP_SUBGRAPH_URI,
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={graphqlClient}>
        <WagmiConfig client={wagmiClient}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Loading />
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/profile" element={<MyTokens />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/token/:id" element={<MyTokenDetails />} />
              <Route exact path="/sale/:id" element={<SeeSaleDetails />} />
              <Route exact path="/payment/:status" element={<Payment />} />
              <Route exact path="*" element={<Navigate to="/" replace />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </WagmiConfig>
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
